import React from "react";
import ImgFooter from "../../assets/emails/footer-email-welcome.png"
import "./not-found.js"

function NotFoundPage() {
  return (
    <>
      <div>
        <span>404 - Página não encontrada</span>
				<img src={ImgFooter} alt="alt"/>
      </div>
    </>
  );
};

export default NotFoundPage;
