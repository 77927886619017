import React, { useState, useEffect, useRef } from "react";
import "./chat.css";

import LogoAmyPetz from "../../assets/logos/logo_amypetz.svg";
import { FiSend } from "react-icons/fi";
import MessageBox from "../../core/components/message-box/message-box";

import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import api from "../../core/config/api";
import MaskInput from "../../core/components/mask-inputs/mask-inputs";

function Chat(props) {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");

  const [typePets, setTypePets] = useState([]);
  const [gendersPet, setGendersPet] = useState([]);

  const [tipoSelecionado, setTipoSelecionado] = useState("");
  const [racas, setRacas] = useState([]);

  const [typeInputText, setTypeInputText] = useState("text");

  const [isEmailValid, setEmailValid] = useState("false");

  useEffect(() => {
    const input = document.getElementById("input-text-usert");
    if (input) {
      input.type = typeInputText;
    }
  }, [typeInputText]);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone_number: "",
  });

  const [petData, setPetData] = useState({
    name: "",
    age: "",
    breed: "",
    type: "",
    gender: "",
    medications: null,
    chronic_illness: null,
  });

  const [chronicIllness, setChronicIllness] = useState([]);

  useEffect(() => {
    setMessages([
      { text: "Olá, seja bem-vindo a AmyPetz", author: "amypetz" },
      {
        text: "Somos uma loja virtual baseada em inteligência artificial que te ajuda a encontrar os melhores produtos e ofertas para o seu amiguinho de estimação!",
        author: "amypetz",
      },
      {
        text: "O melhor é que depois de preencher os seus dados e do seu pet, você faz tudo pelo WhatsApp, esqueça a burocracia de app’s, sites, aqui você tem um experiência única.",
        author: "amypetz",
      },
      { text: "Aqui o seu amiguinho é muito bem-vindo(a)", author: "amypetz" },
      { text: "Como podemos te chamar?", author: "amypetz" },
    ]);
  }, []);

  useEffect(() => {
    async function getAllTypesPet() {
      api.get("/v1/pet/types").then((response) => {
        setTypePets(response.data.TypePets);
      });
    }

    async function getAllGendersPet() {
      api.get("/v1/pet/genders").then((response) => {
        setGendersPet(response.data.Genders);
      });
    }

    async function getAllChronicIllness() {
      api.get("/v1/pet/chronic-illness").then((response) => {
        setChronicIllness(response.data.ChronicIllness);
      });
    }

    getAllGendersPet();
    getAllTypesPet();
    getAllChronicIllness();
  }, []);

  useEffect(() => {
    const fetchRacas = async () => {
      if (tipoSelecionado !== "") {
        try {
          const response = await api.get(
            `/v1/pet/breed?typepet=${tipoSelecionado.toLowerCase()}`
          );
          setRacas(response.data.Breeds);
        } catch (error) {
          console.error("Erro ao obter raças:", error);
        }
      }
    };

    fetchRacas();
  }, [tipoSelecionado]);

  const handleTipoChange = (e) => {
    let type_selected = e.target.value;
    let selectedIndex = e.target.selectedIndex;
    let selectedValue = e.target.options[selectedIndex];
    const id = selectedValue.getAttribute("data-id");

    setPetData({
      ...petData,
      type: id,
    });

    setTipoSelecionado(id);
    setInputText(type_selected);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const OnChangeSelectGenderPet = (e) => {
    let type_selected = e.target.value;
    let selectedIndex = e.target.selectedIndex;
    let selectedValue = e.target.options[selectedIndex];
    const id = selectedValue.getAttribute("data-id");

    setPetData({
      ...petData,
      gender: id,
    });

    setInputText(type_selected);
  };

  const OnChangeSelectForChronicIllness = (e) => {
    let type_selected = e.target.value;
    let selectedIndex = e.target.selectedIndex;
    let selectedValue = e.target.options[selectedIndex];
    const id = selectedValue.getAttribute("data-id");

    setPetData({
      ...petData,
      chronic_illness: id,
    });

    setInputText(type_selected);
  };

  const OnChangeSelectBreedPet = (e) => {
    let type_selected = e.target.value;

    setPetData({
      ...petData,
      breed: type_selected,
    });

    setInputText(type_selected);
  };

  const [isYesOrNoForChronicIllness, setIsYesOrNoForChronicIllness] =
    useState(false);
  const [yesOrNoForChronicIllness, setYesOrNoForChronicIllness] =
    useState(false);

  const OnChangeSelectYesOrNoForChronicIllness = (e) => {
    let type_selected = e.target.value;
    setYesOrNoForChronicIllness(type_selected);
    setInputText(type_selected);
  };

  const [, setIsYesOrNoForMedications] = useState(false);
  const [yesOrNoForMedications, setYesOrNoForMedications] = useState(false);

  const [isFormSendUserAndPetOK, setIsFormSendUserAndPetOK] = useState(false);

  useEffect(() => {
    if (isFormSendUserAndPetOK) {
      api
        .post(`/v1/owner`, {
          name: userData.name,
          email: userData.email,
          phone_number: userData.phone_number,
        })
        .then((response) => {
          let id_owner = response.data.id;
          return api.post(`/v1/pet`, {
            name: petData.name,
            age: parseInt(petData.age),
            type: parseInt(petData.type),
            breed: petData.breed,
            gender: parseInt(petData.gender),
            chronic_illness: parseInt(petData.chronic_illness),
            medications: petData.medications,
            id_owner: id_owner,
          });
        })
        .then((responseOwner) => {
          if (responseOwner.status === 201) {
            console.log("OK Owner e Pet");
          }
        });
    }
  }, [isFormSendUserAndPetOK, petData, userData]);

  const OnChangeSelectYesOrNoForMedications = (e) => {
    let type_selected = e.target.value;

    setYesOrNoForMedications(type_selected);
    setInputText(type_selected);
  };

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    setInputText("");

    const inputTypeHTML = e.target.querySelector("#dynamic-input").type;
    const inputValueHTML = e.target.querySelector("#dynamic-input").value;

    if (inputValueHTML !== "") {
      if (inputTypeHTML === "email" && !isEmailValid) {
        setEmailValid(true);
      } else {
        if (userData["name"] === "") {
          setUserData((prevState) => {
            return { ...prevState, ...{ name: inputText } };
          });
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `Olá, ${inputText}, qual o seu e-mail?`,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `Olá, ${inputText}, qual o seu e-mail?`,
              },
            ])
          );
          setTypeInputText("email");
        } else if (userData["email"] === "") {
          setUserData((prevState) => {
            return { ...prevState, ...{ email: inputText } };
          });
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `${userData["name"]}, qual o numero de telefone?`,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `${inputText}, qual o numero de telefone?`,
              },
            ])
          );
          setTypeInputText("number_phone");
        } else if (userData["phone_number"] === "") {
          setUserData((prevState) => {
            return { ...prevState, ...{ phone_number: inputText } };
          });
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `Obrigada pelas informações sobre você`,
            },
            {
              author: "amypetz",
              text: `${userData["name"]}, agora precisamos entender mais sobre seu amiguinho(a)`,
            },
            {
              author: "amypetz",
              text: `${userData["name"]}, qual o nome do seu amiguinho(a)?`,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `${userData["name"]}, precisamos entender sobre seu amiguinho(a)`,
              },
              {
                author: "amypetz",
                text: `${userData["name"]}, o nome do seu amiguinho(a)?`,
              },
            ])
          );
          setTypeInputText("text");
        } else if (petData["name"] === "") {
          setPetData((prevState) => {
            return { ...prevState, ...{ name: inputText } };
          });
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `Qual a idade do(a) ${inputText}?`,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `Qual a idade do(a) ${inputText}?`,
              },
            ])
          );
          setTypeInputText("number_age");
        } else if (petData["age"] === "") {
          setPetData((prevState) => {
            return { ...prevState, ...{ age: inputText } };
          });

          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `Qual o gênero do(a) ${petData["name"]}?`,
            },
            {
              author: "amypetz",
              text: "",
              listOfGenderPets: [...gendersPet],
              isShowSelectBreed: false,
              isShowSelectGenderPet: true,
              isShowSelectTypePet: false,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `Qual o gênero do(a) ${petData["name"]}?`,
              },
              {
                author: "amypetz",
                text: "",
                listOfGenderPets: [...gendersPet],
                isShowSelectBreed: false,
                isShowSelectGenderPet: true,
                isShowSelectTypePet: false,
              },
            ])
          );
          setTypeInputText("text");
        } else if (petData["type"] === "") {
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            { author: "amypetz", text: `O(a) ${petData["name"]} é um?` },
            {
              author: "amypetz",
              text: "",
              listOfTypePets: [...typePets],
              isShowSelectBreed: false,
              isShowSelectGenderPet: false,
              isShowSelectTypePet: true,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              { author: "amypetz", text: `O(a) ${petData["name"]} é um?` },
              {
                author: "amypetz",
                text: "",
                listOfTypePets: [...typePets],
                isShowSelectBreed: false,
                isShowSelectGenderPet: false,
                isShowSelectTypePet: true,
              },
            ])
          );

          setTypeInputText("text");
        } else if (petData["breed"] === "") {
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            { author: "amypetz", text: `Qual a raça do(a) ${petData["name"]}?` },
            {
              author: "amypetz",
              text: "",
              listOfBreedPet: [...racas],
              isShowSelectBreed: true,
              isShowSelectGenderPet: false,
              isShowSelectTypePet: false,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `Qual a raça do(a) ${petData["name"]}?`,
              },
              {
                author: "amypetz",
                text: "",
                listOfBreedPet: [...racas],
                isShowSelectBreed: true,
                isShowSelectGenderPet: false,
                isShowSelectTypePet: false,
              },
            ])
          );
        } else if (!isYesOrNoForChronicIllness) {
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `${petData["name"]} possui alguma doença crônica?`,
            },
            {
              author: "amypetz",
              text: "",
              listOfBreedPet: [],
              isShowSelectBreed: false,
              isShowSelectGenderPet: false,
              isShowSelectTypePet: false,
              isShowSelectYesOrNoForChronicIllness: true,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `${petData["name"]} possui alguma doença crônica?`,
              },
              {
                author: "amypetz",
                text: "",
                listOfBreedPet: [],
                isShowSelectBreed: false,
                isShowSelectGenderPet: false,
                isShowSelectTypePet: false,
                isShowSelectYesOrNoForChronicIllness: true,
              },
            ])
          );

          setIsYesOrNoForChronicIllness(true);
          setYesOrNoForChronicIllness(inputText);
        } else if (
          (yesOrNoForChronicIllness === "Sim") &
          (petData.chronic_illness === null)
        ) {
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            { author: "amypetz", text: `Quais dessas ele(a) possui?` },
            {
              author: "amypetz",
              text: "",
              listOfBreedPet: [],
              listOfChronicIllness: [...chronicIllness],
              isShowSelectBreed: false,
              isShowSelectGenderPet: false,
              isShowSelectTypePet: false,
              isShowSelectYesOrNoForChronicIllness: false,
              isShowForChronicIllness: true,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              { author: "amypetz", text: `Quais dessas ele(a) possui?` },
              {
                author: "amypetz",
                text: "",
                listOfBreedPet: [],
                listOfChronicIllness: [...chronicIllness],
                isShowSelectBreed: false,
                isShowSelectGenderPet: false,
                isShowSelectTypePet: false,
                isShowSelectYesOrNoForChronicIllness: false,
                isShowForChronicIllness: true,
              },
            ])
          );
        } else if (yesOrNoForChronicIllness !== null) {
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `${petData["name"]} toma algum medicamento?`,
            },
            {
              author: "amypetz",
              text: "",
              listOfBreedPet: [],
              isShowSelectBreed: false,
              isShowSelectGenderPet: false,
              isShowSelectTypePet: false,
              isShowSelectYesOrNoForChronicIllness: false,
              isShowSelectYesOrNoForMedications: true,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `${petData["name"]} toma algum medicamento?`,
              },
              {
                author: "amypetz",
                text: "",
                listOfBreedPet: [],
                isShowSelectBreed: false,
                isShowSelectGenderPet: false,
                isShowSelectTypePet: false,
                isShowSelectYesOrNoForChronicIllness: false,
                isShowSelectYesOrNoForMedications: true,
              },
            ])
          );

          setYesOrNoForChronicIllness(null);

          setIsYesOrNoForMedications(true);
          setYesOrNoForMedications(inputText);
        } else if (
          (yesOrNoForMedications === "Sim") &
          (petData.medications === null)
        ) {
          setMessages([
            ...messages,
            { author: "user", text: inputText },
            {
              author: "amypetz",
              text: `Por favor, digite os medicamentos separados por vírgula`,
              listOfBreedPet: [],
              isShowSelectBreed: false,
              isShowSelectGenderPet: false,
              isShowSelectTypePet: false,
              isShowSelectYesOrNoForChronicIllness: false,
              isShowSelectYesOrNoForMedications: false,
            },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              {
                author: "amypetz",
                text: `Por favor, digite os medicamentos separados por vírgula`,
                listOfBreedPet: [],
                isShowSelectBreed: false,
                isShowSelectGenderPet: false,
                isShowSelectTypePet: false,
                isShowSelectYesOrNoForChronicIllness: false,
                isShowSelectYesOrNoForMedications: false,
              },
            ])
          );

          setYesOrNoForMedications(null);
        } else {
          if (yesOrNoForMedications === null) {
            setPetData((prevState) => {
              return { ...prevState, ...{ medications: inputText } };
            });
          }

          setMessages([
            ...messages,
            { author: "user", text: inputText },
            { author: "amypetz", text: `Obrigada pelas informações` },
            {
              author: "amypetz",
              text: `Fique atento ao seu e-mail e whatsapp`,
            },
            {
              author: "amypetz",
              text: `Logo mais você vai ter direto no seu whatsapp a Loja mais querida do seu Pet, com dicas de produtos e promoções que preparamos especialmente para você e para o seu amiguinho(a) :D`,
            },
            { author: "amypetz", text: `🥳🎉`, isShowWhatsappbutton: false },
            // { author: "amypetz", text: `Agora entre na nossa loja no WhatsApp e conheça os produtos e promoções que personalizamos especificamente para o(a) ${petData["name"]}` },
            // { author: "amypetz", text: '', isShowWhatsappbutton: false },
          ]);

          localStorage.setItem(
            "chatMessages",
            JSON.stringify([
              ...messages,
              { author: "user", text: inputText },
              { author: "amypetz", text: `Obrigada pelas informações` },
              {
                author: "amypetz",
                text: `Fique atento ao seu e-mail e whatsapp`,
              },
              {
                author: "amypetz",
                text: `Logo mais você vai ter direto no seu whatsapp a Loja mais querida do seu Pet, com dicas de produtos e promoções que preparamos especialmente para você e para o seu amiguinho(a) :D`,
              },
              { author: "amypetz", text: `🥳🎉`, isShowWhatsappbutton: false },
            ])
          );

          setIsFormSendUserAndPetOK(true);
        }
      }
    }
  };

  const containerRef = useRef(null);

  const [displayedMessages, setDisplayedMessages] = useState([]);
    const [processedCount, setProcessedCount] = useState(0);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    useEffect(() => {
        if (processedCount < messages.length) {
            const processNewMessages = async () => {
                const newMessages = messages.slice(processedCount);

                for (const message of newMessages) {
                    await delay(2500); // Define o atraso de 1 segundo (1000ms)
                    setDisplayedMessages((prev) => [...prev, message]);
                }
                setProcessedCount(messages.length);
            };

            processNewMessages();
        }
    }, [messages, processedCount]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedMessages]);

  return (
    <>
      <nav className="nav-logo">
        <Link to="/">
          <div className="go-back">
            <FiArrowLeft size={30} color="#840aff" />
            <span>voltar</span>
          </div>
        </Link>
        <img src={LogoAmyPetz} alt="Logo Amy Petz" />
      </nav>
      <div className="container-chat">
        <div className="content-chat">
          <div className="conversation-container" ref={containerRef}>
            {displayedMessages.map((message, index) => {
              return message.author === "user" ? (
                <MessageBox type="user" message={message.text} />
              ) : (
                <>
                  <MessageBox
                    type="amypetz"
                    message={message.text}
                    listOfTypePets={message.listOfTypePets}
                    listOfGenderPets={message.listOfGenderPets}
                    listOfBreedPet={message.listOfBreedPet}
                    listOfChronicIllness={message.listOfChronicIllness}
                    isShowSelectTypePet={message.isShowSelectTypePet}
                    isShowSelectGenderPet={message.isShowSelectGenderPet}
                    isShowSelectBreed={message.isShowSelectBreed}
                    isShowWhatsappbutton={message.isShowWhatsappbutton}
                    isShowSelectYesOrNoForChronicIllness={
                      message.isShowSelectYesOrNoForChronicIllness
                    }
                    isShowForChronicIllness={message.isShowForChronicIllness}
                    isShowSelectYesOrNoForMedications={
                      message.isShowSelectYesOrNoForMedications
                    }
                    onChangeSelectTypePet={handleTipoChange}
                    onChangeSelectGenderPet={OnChangeSelectGenderPet}
                    onChangeSelectBreedPet={OnChangeSelectBreedPet}
                    onChangeSelectForChronicIllness={
                      OnChangeSelectForChronicIllness
                    }
                    onChangeSelectYesOrNoForChronicIllness={
                      OnChangeSelectYesOrNoForChronicIllness
                    }
                    onChangeSelectYesOrNoForMedications={
                      OnChangeSelectYesOrNoForMedications
                    }
                  />
                </>
              );
            })}
          </div>

          <div className="footer-chat">
            <form onSubmit={handleMessageSubmit}>
              <MaskInput
                type_input={typeInputText}
                placeholder="Digite a sua mensagem"
                onChange={handleInputChange}
                value={inputText}
              />
              <button aria-label="Botão para enviar mensagem">
                <FiSend className="icon-send" size={30} color="#ACACAC" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
