import React from "react";
import "./message-box.css";
import LogoAmyPetz500White from "../../../assets/logos/logo_amypetz_500_white.svg";
import TypingAnimation from "../../../utils/TypingAnimation";
import { Link } from "react-router-dom";

function MessageBox(props) {
  let type = props.type;
  let message = props.message;
  let listOfTypePets = props.listOfTypePets;
  let listOfPetBreads = props.listOfBreedPet;
  let listOfGenderPets = props.listOfGenderPets;
  let listOfChronicIllness = props.listOfChronicIllness;

  let isShowSelectTypePet = props.isShowSelectTypePet;
  let isShowSelectGenderPet = props.isShowSelectGenderPet;
  let isShowSelectBreed = props.isShowSelectBreed;
  let isShowWhatsappbutton = props.isShowWhatsappbutton;
  let isShowSelectYesOrNoForChronicIllness =
    props.isShowSelectYesOrNoForChronicIllness;
  let isShowForChronicIllness = props.isShowForChronicIllness;
  let isShowSelectYesOrNoForMedications =
    props.isShowSelectYesOrNoForMedications;

  let onChangeSelectTypePet = props.onChangeSelectTypePet;
  let onChangeSelectGenderPet = props.onChangeSelectGenderPet;
  let onChangeSelectBreedPet = props.onChangeSelectBreedPet;
  let onChangeSelectForChronicIllness = props.onChangeSelectForChronicIllness;
  let onChangeSelectYesOrNoForChronicIllness =
    props.onChangeSelectYesOrNoForChronicIllness;
  let onChangeSelectYesOrNoForMedications =
    props.onChangeSelectYesOrNoForMedications;

  function selectPet(listOfTypePets) {
    return (
      <>
        <select
          name="type"
          id="type"
          className="select-pet-type"
          onChange={onChangeSelectTypePet}
        >
          <option value="" selected disabled>
            Selecione...
          </option>
          {listOfTypePets.map((item, index) => {
            return (
              <option value={item.replace(/_/g, " ")} data-id={index}>
                {item.replace(/_/g, " ")}
              </option>
            );
          })}
        </select>
      </>
    );
  }

  function selectYesOrNoForChronicIllness() {
    return (
      <>
        <select
          name="type"
          id="type"
          className="select-pet-type"
          onChange={onChangeSelectYesOrNoForChronicIllness}
        >
          <option value="" selected disabled>
            Selecione...
          </option>
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </select>
      </>
    );
  }

  function selectYesOrNoForMedications() {
    return (
      <>
        <select
          name="type"
          id="type"
          className="select-pet-type"
          onChange={onChangeSelectYesOrNoForMedications}
        >
          <option value="" selected disabled>
            Selecione...
          </option>
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </select>
      </>
    );
  }

  function selectForChronicIllness(listOfChronicIllness) {
    return (
      <>
        <select
          name="type"
          id="type"
          className="select-pet-chronic-illness"
          onChange={onChangeSelectForChronicIllness}
        >
          <option value="" selected disabled>
            Selecione...
          </option>
          {listOfChronicIllness.map((item, index) => {
            return (
              <option value={item.replace(/_/g, " ")} data-id={index}>
                {item.replace(/_/g, " ")}
              </option>
            );
          })}
        </select>
      </>
    );
  }

  function selectPetGender(listOfGenderPets) {
    return (
      <>
        <select
          name="type"
          id="type"
          className="select-pet-gender"
          onChange={onChangeSelectGenderPet}
        >
          <option value="" selected disabled>
            Selecione...
          </option>
          {listOfGenderPets.map((item, index) => {
            return (
              <option value={item} data-id={index}>
                {item}
              </option>
            );
          })}
        </select>
      </>
    );
  }

  function buttonRedirectWhatsapp() {
    return (
      <div className="button-whatsapp-chat">
        <Link to="https://api.whatsapp.com/send?phone=5511994958585&amp;text=Olá AmyPetz, eu gostaria de comprar produtos para o meu amiguinho(a)">
          Clique aqui
        </Link>
        <span> e vá para a loja da AmyPetz</span>
      </div>
    );
  }

  function selectPetBreed(listOfBreeds) {
    return (
      <>
        <select
          name="type"
          id="type"
          className="select-pet-breed"
          onChange={onChangeSelectBreedPet}
        >
          <option value="" selected disabled>
            Selecione...
          </option>
          {listOfBreeds.map((item, index) => {
            return (
              <option value={item.replace(/_/g, " ")} data-id={index}>
                {item.replace(/_/g, " ")}
              </option>
            );
          })}
        </select>
      </>
    );
  }

  if (type === "amypetz") {
    return (
      <div className="type-amypetz">
        <img src={LogoAmyPetz500White} alt="Logo AmyPetz" />
        <div className="about-and-message">
          <span className="name">AmyPetz</span>
          <div className="message">
            {isShowSelectTypePet ? (
              selectPet(listOfTypePets)
            ) : isShowSelectGenderPet ? (
              selectPetGender(listOfGenderPets)
            ) : isShowSelectBreed ? (
              selectPetBreed(listOfPetBreads)
            ) : isShowSelectYesOrNoForChronicIllness ? (
              selectYesOrNoForChronicIllness()
            ) : isShowForChronicIllness ? (
              selectForChronicIllness(listOfChronicIllness)
            ) : isShowSelectYesOrNoForMedications ? (
              selectYesOrNoForMedications()
            ) : isShowWhatsappbutton ? (
              buttonRedirectWhatsapp()
            ) : (
              <TypingAnimation text={message} />
            )}
          </div>
        </div>
      </div>
    );
  } else if (type === "user") {
    return (
      <div className="type-user">
        <div className="about-and-message">
          <div className="message">{message}</div>
        </div>
      </div>
    );
  }
}

export default MessageBox;