import React from "react";
import "./home.css";
import LogoAmyPetz from "../../assets/logos/logo_amypetz.svg";
// import WhatsappIcon from "../../assets/icons/whatsapp_icon.png";
import { Link } from "react-router-dom";


export default function Home() {
  return (
    <>
      <div className="container-home">
        <img src={LogoAmyPetz} alt="Logo AmyPetz" className="logo-amypetz-home"/>
        <span>A loja preferida do seu amiguinho de estimação no seu WhatsApp</span>
        <Link to="/chat"><button>Começar</button></Link>
        {/* <Link to="https://api.whatsapp.com/send?phone=5511994958585&amp;text=Olá AmyPetz, eu gostaria de comprar produtos para o meu amiguinho(a)" target="_blank" className="float-whatsapp-icon"><img src={WhatsappIcon} alt="Whatsapp AmyPetz" /></Link> */}
      </div>
    </>
  );
}
