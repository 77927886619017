import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from "./pages/home/home";
import Chat from "./pages/chat/chat";
import NotFoundPage from "./pages/not-found/not-found";
import LandingPage from "./pages/landing-page/LandingPage";

const Endpoints = () => {
  return (
    <Router>
      <Routes>
        <Route element={<NotFoundPage />} />
        <Route path="/" element={<LandingPage />} />
          <Route path="/old" element={<Home />} />
        <Route path="/chat" element={<Chat />} />
      </Routes>
    </Router>
  );

}

export default Endpoints;
