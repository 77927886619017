import React from "react";
import InputMask from "react-input-mask";

function MaskInput(props) {
  let { type_input, onChange, placeholder, value } = props;

  let actual_type = "";
  let actual_mask = "";
  let step_number = "";

  if (type_input === "email") {
    actual_type = "email";
  } else if (type_input === "number_phone") {
    actual_type = "text";
    actual_mask = "(99) 99999-9999";
  } else if (type_input === "number_age") {
    actual_type = "number";
    step_number = ".01";
  }

  return (
    <>
      <InputMask
        mask={actual_mask}
        id="dynamic-input"
        onChange={onChange}
        name="message-user-send"
        type={actual_type}
        placeholder={placeholder}
        value={value}
        min="0.0"
        step={step_number}
      />
    </>
  );
}

export default MaskInput;
