import './App.css';
import './global.css';
import './reset.css';
import Endpoints from './routes';

function App() {
  return (
    <>
      <Endpoints />
    </>
  );
}

export default App;
