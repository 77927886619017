import React from "react";

import {
  BsBag,
  BsArrowRight,
  BsWhatsapp,
  BsCreditCard,
  BsPlus,
} from "react-icons/bs";
import { PiDogLight } from "react-icons/pi";
import { RiSubtractLine } from "react-icons/ri";

import { Link } from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom';

import QrCodeWhatsapp from "../../assets/qrcode_whatsapp.png";
// import VideoCelular from "../../assets/videos/celular-mostrando-os-benefícios-do-clubz.mp4";
import ActiveOffer from "../../assets/videos/active-offer.mp4";
import LogoAmyPetz from "../../assets/logos/logo_amypetz.svg";
import WhatsappIcon from "../../assets/icons/whatsapp_icon.png"

import { Link as LinkReactRouterDom } from "react-router-dom";

import "./LandingPage.css";
class FAQItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { question, answer } = this.props;
    const { isOpen } = this.state;

    const IconComponent = isOpen ? RiSubtractLine : BsPlus;
    return (
      <div className="faq-item">
        <div className="question" onClick={this.toggleOpen}>
          <h2 className="title-faq">{question}</h2>
          <IconComponent size={40} />
        </div>
        <div className={`answer ${isOpen ? "open" : "closed"} response-faq`}>

          <div dangerouslySetInnerHTML={{ __html: answer }} className="text-responses"></div>
        </div>
      </div>
    );
  }
}

function LandingPage() {
  return (
    <>
      <nav className="navbar-container">
        <img src={LogoAmyPetz} alt="Petshop AmyPetz" />
        <ul>
          <li>
            <Link
              activeClass="benefits"
              to="benefits"
              spy={true}
              smooth={true}
              offset={-90}
              duration={500}
            >
              Benefícios
            </Link>
          </li>
          <li>
            <Link
              activeClass="benefits"
              to="steps"
              spy={true}
              smooth={true}
              offset={-90}
              duration={500}
            >
              Como funciona
            </Link>
          </li>
          <li>
            <Link
              activeClass="benefits"
              to="faq"
              spy={true}
              smooth={true}
              offset={-90}
              duration={500}
            >
              Dúvidas
            </Link>
            
          </li>
        </ul>
      </nav>
      <br />
      <br />
      <br />
      <main className="container-landing-page">
        <article className="container-welcome" id="benefits">
          <div className="description-welcome">
            <div className="principal-description">
              <h2>A loja preferida do seu amiguinho de estimação no seu WhatsApp</h2>
              <span>
                Tudo o que seu Pet precisa e muitas vantagens para você!<br/>
                Somos uma loja virtual baseada em inteligência artificial que te ajuda a encontrar os melhores produtos e ofertas para o seu amiguinho de estimação dentro do seu WhatsApp.
                Aproveite descontos exclusivos, entrega rápida e atendimento 24/7, tudo sem sair do WhatsApp. 
              </span>
                    
              <div className="container-benefits">
                <div className="benefits-square">
                  <div className="item">
                    <img src="" alt="" />
                    <span>Alimentação</span>
                  </div>
                </div>
                <div className="benefits-square">
                  <div className="item">
                    <img src="" alt="" />
                    <span>Farmácia</span>
                  </div>
                </div>
                <div className="benefits-square">
                  <div className="item">
                    <img src="" alt="" />
                    <span>Brinquedos</span>
                  </div>
                </div>
                <div className="benefits-square">
                  <div className="item">
                    <img src="" alt="" />
                    <span>Ofertas Personalizadas</span>
                  </div>
                </div>
              </div>
              <LinkRouter  to="/chat"><button className="button-start">Começar </button></LinkRouter> 
            </div>
            {/* <video
              loop
              playsinline
              autoplay=""
              muted
              width="480"
              height="657"
              title="Celular mostrando os benefícios do clubz"
              aria-label="Celular mostrando os benefícios do clubz"
            >
              <source src={VideoCelular} type="video/mp4" />
            </video> */}
          </div>
        </article>
        <article className="container-steps" id="steps">
          <div className="step-description">
            <h2>Chega mais, vem conhecer como funcionamos!</h2>
            <br />
            <span>
              Aproveitar a AmyPetz não tem mistério. É mais rápido que pet aceitando petiscos. 
            </span>
          </div>
          <div className="informations-of-step">
            <div className="qrcode-whatsapp">
              <img src={QrCodeWhatsapp} alt="QrCode para o whatsapp AmyPetz" />
              <br />
              <span>Acesse o nosso whatsapp</span>
            </div>
            <div className="steps">
              <div className="step">
                <div className="icon-step">
                  <BsWhatsapp />
                </div>
                <span className="description-step">
                  Acesse o nosso whatsapp
                </span>
              </div>
              <BsArrowRight size={50} className="arrow-step" />
              <div className="step">
                <div className="icon-step">
                  <BsBag />
                </div>
                <span className="description-step">
                  Selecione seu produto no catálogo
                </span>
              </div>
              <BsArrowRight size={50} className="arrow-step" />
              <div className="step">
                <div className="icon-step">
                  <BsCreditCard />
                </div>
                <span className="description-step">Realize o Pagamento</span>
              </div>
              <BsArrowRight size={50} className="arrow-step" />
              <div className="step">
                <div className="icon-step">
                  <PiDogLight />
                </div>
                <span className="description-step">
                  Pronto, seu amiguinho(a) feliz!
                </span>
              </div>
            </div>
          </div>
        </article>
        <br />
        <br />
        <article className="container-offers">
          <video
            loop
            autoplay=""
            muted
            width="257"
            height="400"
            title="Celular mostrando os benefícios do clubz"
            aria-label="Celular mostrando os benefícios do clubz"
          >
            <source src={ActiveOffer} type="video/mp4" />
          </video>

          <div className="description-container">
            <h2>Os produtos e ofertas vão até você!</h2>
            <br />
            <span>
              Com a <b>Inteligência Artificial</b> da Amypetz, recomendamos os melhores produtos de acordo com o perfil do seu amiguinho.
              <br />
              Esqueça a complexidade ficar procurando e criando contas em vários sites, na AmyPetz fazemos tudo por você e dentro do seu WhatsApp. 
            </span>
          </div>
        </article>
      </main>

      <article className="faq-container" id="faq">
        <h2>Ficou com Alguma Dúvida?</h2> <br />
        <span>
          Confira algumas perguntas e respostas ou nos envie um{" "}
          <a href="mailto:atendimento@amypetz.com.br">e-mail</a>
        </span>
        <br />
        <br />
        <div className="faq-content">
          <FAQItem question="Como faço o cadastro na AmyPetz?" answer="Não temos cadastro, batemos um papo no WhatsApp. <br/>Acesse nosso WhatsApp<br/>Pronto! Agora é só acessar o catálogo de produtos e aproveitar" />
          <FAQItem question="Como recebo ofertas personalizadas AmyPetz?" answer="1. No nosso site clique no botão começar<br/> 2.reencha as informações sobre o seu perfil e do seu pet <br/>3.Tudo certo! Agora é aguardar as ofertas personalizadas para o seu pet no seu WhatsApp" />
          <FAQItem question="Preciso pagar alguma coisa para usar a AmyPetz?" answer="NÃO! A AmyPetz é gratuita, você contribui apenas com uma taxinha no momento que compra algum produto." />
          <FAQItem question="Consigo agendar serviços como banho e tosa via AmyPetz?" answer="Em breve! Por enquanto, os parceiros optaram pela exposição de produtos específicos quais os comercializam e entregam. " />
        </div>
      </article>


      <article className="has-question">
        <div className="has-question-content">
          <div className="text">
            <h2>Precisa de ajuda?</h2><br/>
            <span>Entre em contato com a gente pelo Whatsapp</span> 
          </div>
          
          <LinkReactRouterDom to="https://api.whatsapp.com/send?phone=5511994958585&amp;text=Olá AmyPetz, eu gostaria de comprar produtos para o meu amiguinho(a)" target="_blank"><button> Whatsapp</button></LinkReactRouterDom>
        </div>
      </article>

      <footer className="footer-container">
          <div className="footer-item">
            <h3>Navegue</h3>
            <Link
              activeClass="benefits"
              to="benefits"
              spy={true}
              smooth={true}
              offset={-90}
              duration={500}
            >
              Benefícios
            </Link>
            <Link
              activeClass="benefits"
              to="steps"
              spy={true}
              smooth={true}
              offset={-90}
              duration={500}
            >
              Como funciona
            </Link>
            <Link
              activeClass="benefits"
              to="faq"
              spy={true}
              smooth={true}
              offset={-90}
              duration={500}
            >
              Dúvidas
            </Link>
          </div>
          <div className="footer-item">
            <h3>Entre em contato</h3>
            <span>(11) 99495-8585</span>
            <span>atendimento@amypetz.com.br</span>
          </div>
      </footer>

      <LinkReactRouterDom to="https://api.whatsapp.com/send?phone=5511994958585&amp;text=Olá AmyPetz, preciso de atendimento" target="_blank" className="float-whatsapp-icon"><img src={WhatsappIcon} alt="Whatsapp AmyPetz" /></LinkReactRouterDom>
    </>
  );
}

export default LandingPage;
